<template>
  <div>
    <Menu/>
    <!-- banners -->
    <section v-if="$store.state.searchState == 'Residential'">
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="portfolio-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h3 class="display-4" v-if="loading">Loading</h3>
                  <h1 class="display-2 pb-5">Residential</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="$store.state.searchState == 'Commercial'">
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="commercial-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h3 class="display-4" v-if="loading">Loading</h3>
                  <h1 class="display-2 pb-5">Commercial</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="$store.state.searchState == 'Farms'">
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="farms-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h3 class="display-4" v-if="loading">Loading</h3>
                  <h1 class="display-2 pb-5">Farms & Smallholdings</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- residential search -->
    <section v-if="$store.state.searchState == 'Residential' && !loading">
      <div class="container text-center">      
        <div class="row mt-5 mb-5 justify-content-center">
          
          <div class="col-md-3" v-if="$store.state.filterProperty != 'Vacant Land'">
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterType == 'Residential For Sale' }" 
              v-on:click="$store.state.filterType = 'Residential For Sale';">
              Buy
            </button>
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterType == 'Residential To Let' }" 
              v-on:click="$store.state.filterType = 'Residential To Let';">
              Rent
            </button>
          </div>
          
          <div class="col-md-3">
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterProperty == 'House' }" 
              v-on:click="$store.state.filterProperty = 'House'; $store.state.filterType = 'Residential For Sale';">
              House
            </button>
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterProperty == 'Lodge' }" 
              v-on:click="$store.state.filterProperty = 'Lodge'; $store.state.filterType = 'Residential For Sale';">
              Lodge
            </button>
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterProperty == 'Apartment' }" 
              v-on:click="$store.state.filterProperty = 'Apartment'; $store.state.filterType = 'Residential For Sale';">
              Apartment
            </button>
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterProperty == 'Vacant Land' }" 
              :disabled="$store.state.filterProperty == 'Vacant Land'" 
              v-on:click="$store.state.filterProperty = 'Vacant Land'; $store.state.filterType = 'Residential For Sale';">
              Plot
            </button>
          </div>
          
          <div class="col-md-3" v-if="$store.state.filterProperty != 'Vacant Land'">
            <div class="container-fluid">
              <div class="row" v-if="$store.state.filterType != 'Residential To Let'">
                <div class="col-12">
                    <p class="text-start mb-1">Max Price - R {{ maxPrice / 1000000 }} million
                    <input type="range" class="form-range" v-model="maxPrice" :min="2000000" :max="200000000" :step="1000000" /></p>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-6">
                  <p class="text-start"><small>Beds - {{ beds }}</small></p>
                  <input type="range" class="form-range" v-model="beds" min="1" max="20" step="1" />
                </div>
                <div class="col-6">
                  <p class="text-start"><small>Baths - {{ baths }}</small></p>
                  <input type="range" class="form-range" v-model="baths" min="1" max="20" step="1" />
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-md-3 ps-5 pt-1">
            <input type="text" class="form-control mb-3 w-100" placeholder="Enter a location here" v-model="$store.state.search.text" />
            <ul v-if="locationSuggestions.length > 0 && $store.state.search.text != ''" class="list-group">
              <li v-for="suggestion in locationSuggestions" :key="suggestion" class="list-group-item">
                <div v-on:click="$store.state.search.text = suggestion" style="cursor: pointer;">{{ suggestion }}</div>
              </li>
            </ul>
          </div>

        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <button class="btn btn-primary filter-btn" :class="{ 'active': highToLow }" v-on:click="highToLow = true" style="font-size: 10px;">price high to low</button>
            <button class="btn btn-primary filter-btn" :class="{ 'active': !highToLow }" v-on:click="highToLow = false" style="font-size: 10px;">price low to high</button>
          </div>
        </div>

        <div class="row" v-if="!loading">

          <div class="col-md-6 mb-4" v-for="fItem in filteredListings" v-if="fItem[0].listing_type == $store.state.filterType && fItem[0].property_type == $store.state.filterProperty">
            <div class="banner-section wineland-property p-4 h-100 bg-light">
              <div class="row">
                <div class="col-md-5">
                  <div class="portfolio-thumbnail">
                    <a :href="'/property/' + fItem[0].id"><img :src="fItem[0].listing_images[0]" class="w-100 force-height"></a>
                  </div>
                </div>
                <div class="col-md-7 text-start">
                  <span class="tag">{{ fItem[0].listing_type }}</span>
                  <span class="tag">{{ fItem[0].property_type }}</span>
                  <p class="mt-4"><strong>{{ formatCurrency(fItem[0].price) }}</strong></p>
                  <p class="text-uppercase"><strong>{{ fItem[0].marketing_heading }}</strong></p>
                  <p>{{ fItem[0].loc_suburb }}, {{ fItem[0].loc_region }}</p>
                  <p class="mt-4"><a class="btn btn-cta filter-btn" :href="'/property/' + fItem[0].id">VIEW <i class="bi bi-arrow-right"></i></a></p>
                </div>
              </div>
            </div>  
          </div>

        </div>
      </div>
    </section>
    <!-- commercial search -->
    <section v-if="$store.state.searchState == 'Commercial' && !loading">
      <div class="container text-center">
        <div class="row mt-5 mb-5 justify-content-center">

          <div class="col-md-3">
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterType == 'Commercial For Sale' }" 
              v-on:click="$store.state.filterType = 'Commercial For Sale'; $store.state.searchState = 'Commercial'">
              Buy
            </button>
            <button 
              class="btn btn-primary filter-btn filterOne" 
              :class="{ 'active': $store.state.filterType == 'Commercial To Let' }" 
              v-on:click="$store.state.filterType = 'Commercial To Let'; $store.state.searchState = 'Commercial'">
              Rent
            </button>
          </div>
          
          <div class="col-md-3 ps-5 pt-1">
            <input type="text" class="form-control mb-3 w-100" placeholder="Enter a location here" v-model="$store.state.search.text" />
            <ul v-if="locationSuggestionsCommercial.length > 0 && $store.state.search.text != ''" class="list-group">
              <li v-for="suggestion in locationSuggestionsCommercial" :key="suggestion" class="list-group-item">
              {{ suggestion }}
              </li>
            </ul>
          </div>

        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <button class="btn btn-primary filter-btn" :class="{ 'active': highToLow }" v-on:click="highToLow = true" style="font-size: 10px;">price high to low</button>
            <button class="btn btn-primary filter-btn" :class="{ 'active': !highToLow }" v-on:click="highToLow = false" style="font-size: 10px;">price low to high</button>
          </div>
        </div>

        <div class="row" v-if="!loading">
          <div class="col-md-6 mb-4" v-for="fItem in filteredListingsCommercial" v-if="fItem[0].listing_type == $store.state.filterType && fItem[0].property_type != 'Farm'">           
            <div class="banner-section wineland-property p-4 h-100 bg-light">
              <div class="row">
                <div class="col-md-5">
                  <div class="portfolio-thumbnail">
                    <a :href="'/property/' + fItem[0].id"><img :src="fItem[0].listing_images[0]" class="w-100 force-height"></a>
                  </div>
                </div>
                <div class="col-md-7 text-start">
                  <span class="tag">{{ fItem[0].listing_type }}</span>
                  <span class="tag">{{ fItem[0].property_type }}</span>
                  <p class="mt-4"><strong>{{ formatCurrency(parseInt(fItem[0].price)) }}</strong></p>
                  <p class="text-uppercase"><strong>{{ fItem[0].marketing_heading }}</strong></p>
                  <p>{{ fItem[0].loc_suburb }}, {{ fItem[0].loc_region }}</p>
                  <p class="mt-4"><a class="btn btn-cta filter-btn" :href="'/commercial/' + fItem[0].id">VIEW <i class="bi bi-arrow-right"></i></a></p>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </section>
    <!-- farms search -->
    <section v-if="$store.state.searchState == 'Farms' && !loading">
      <div class="container text-center">
        <div class="row mt-5 mb-5 justify-content-center">

          <div class="col-3 text-center">
              <p class="text-start">Maximum Price - R {{ maxPrice / 1000000 }} million
              <input type="range" class="form-range" v-model="maxPrice" :min="2000000" :max="200000000" :step="1000000" /></p>
          </div>
          
          <div class="col-md-3 ps-5 pt-1">
            <input type="text" class="form-control mb-3 w-100" placeholder="Enter a location here" v-model="$store.state.search.text" />
            <ul v-if="locationSuggestionsCommercial.length > 0 && $store.state.search.text != ''" class="list-group">
              <li v-for="suggestion in locationSuggestionsCommercial" :key="suggestion" class="list-group-item">
              {{ suggestion }}
              </li>
            </ul>
          </div>

        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <button class="btn btn-primary filter-btn" :class="{ 'active': highToLow }" v-on:click="highToLow = true" style="font-size: 10px;">price high to low</button>
            <button class="btn btn-primary filter-btn" :class="{ 'active': !highToLow }" v-on:click="highToLow = false" style="font-size: 10px;">price low to high</button>
          </div>
        </div>

        <div class="row" v-if="!loading">
          <div class="col-md-6 mb-4" v-for="fItem in filteredListingsCommercial" v-if="fItem[0].listing_type == $store.state.filterType && fItem[0].property_type == $store.state.filterProperty">           
            <div class="banner-section wineland-property p-4 h-100 bg-light">
              <div class="row">
                <div class="col-md-5">
                  <div class="portfolio-thumbnail">
                    <a :href="'/property/' + fItem[0].id"><img :src="fItem[0].listing_images[0]" class="w-100 force-height"></a>
                  </div>
                </div>
                <div class="col-md-7 text-start">
                  <span class="tag">{{ fItem[0].listing_type }}</span>
                  <span class="tag">{{ fItem[0].property_type }}</span>
                  <p class="mt-4">
                    <strong>
                      <!-- If poa is true, show "POA", otherwise format the price -->
                      {{ fItem[0].poa == "true" ? 'POA' : formatCurrency(parseInt(fItem[0].price)) }}
                    </strong>
                  </p>
                  <p class="text-uppercase"><strong>{{ fItem[0].marketing_heading }}</strong></p>
                  <p>{{ fItem[0].loc_suburb }}, {{ fItem[0].loc_region }}</p>
                  <p class="mt-4"><a class="btn btn-cta filter-btn" :href="'/commercial/' + fItem[0].id">VIEW <i class="bi bi-arrow-right"></i></a></p>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>

// Portfolio - Direct 
import axios from 'axios'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer
  },
  data() {
    return {
      fusionListings: [],
      fusionListingsCommercial: [],
      loading: true,
      selectedType: null,
      selectedLocations: [],
      minPrice: 0,
      maxPrice: 200000000,
      currentPage: 1,
      itemsPerPage: 8,
      highToLow: true,
      beds: 20,
      baths: 20
    }
  },
  mounted() {
    this.fetchListings()
    this.fetchListingsCommercial()
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredListings.length / this.itemsPerPage);
    },
    paginatedListingsFiltered() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredListings.slice(startIndex, endIndex);
    },
    filteredListings() {
      let filteredListings = this.fusionListings
        .filter(listing => {
          const locationSearchFilter = this.$store.state.search.text
            ? `${listing[0].loc_suburb}, ${listing[0].loc_region}, ${listing[0].marketing_heading}`
                .toLowerCase()
                .includes(this.$store.state.search.text.toLowerCase())
            : true;
          const minPriceFilter = listing[0].price >= this.minPrice;
          const maxPriceFilter = listing[0].price <= this.maxPrice;    
          const maxBaths = listing[0].bathrooms <= this.baths;
          const maxBeds = listing[0].bedrooms <= this.beds;
          const hasFeaturedImage = listing[0].listing_images && listing[0].listing_images.length > 0;
          return locationSearchFilter && minPriceFilter && maxPriceFilter && maxBaths && maxBeds && hasFeaturedImage;
        });
      if (this.highToLow) {
        filteredListings.sort((a, b) => b[0].price - a[0].price);
      } else {
        filteredListings.sort((a, b) => a[0].price - b[0].price);
      }
      return filteredListings;
    },
    filteredLocations() {
      return this.locations.filter((location) =>
        location.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    locationSuggestions() {
      const uniqueLocations = Array.from(
        new Set(
          this.fusionListings.map(
            item =>
              `${item[0].loc_suburb}, ${item[0].loc_region}`
          )
        )
      );
      return uniqueLocations.filter(location =>
        location.toLowerCase().includes(this.$store.state.search.text.toLowerCase())
      );
    },
    filteredListingsCommercial() {
      let filteredListings = this.fusionListingsCommercial
        .filter(listing => {
          const locationSearchFilter = this.$store.state.search.text
            ? `${listing[0].loc_suburb}, ${listing[0].loc_region}`
                .toLowerCase()
                .includes(this.$store.state.search.text.toLowerCase())
            : true;

          // Check for POA properties separately
          const isPOA = listing[0].poa === true || parseInt(listing[0].price) === 999999999;
          const minPriceFilter = isPOA || parseInt(listing[0].price) >= this.minPrice;
          const maxPriceFilter = isPOA || parseInt(listing[0].price) <= this.maxPrice;
          const hasFeaturedImage = listing[0].listing_images && listing[0].listing_images.length > 0;
          return locationSearchFilter && minPriceFilter && maxPriceFilter && hasFeaturedImage;
        });

      if (this.highToLow) {
        filteredListings.sort((a, b) => b[0].price - a[0].price);
      } else {
        filteredListings.sort((a, b) => a[0].price - b[0].price);
      }

      return filteredListings;
    },
    filteredLocationsCommercial() {
      return this.locations.filter((location) =>
        location.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    locationSuggestionsCommercial() {
      const uniqueLocations = Array.from(
        new Set(
          this.fusionListingsCommercial.map(
            item =>
              `${item[0].loc_suburb}, ${item[0].loc_region}`
          )
        )
      );
      return uniqueLocations.filter(location =>
        location.toLowerCase().includes(this.$store.state.search.text.toLowerCase())
      );
    },
  },
  methods: {
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage: function () {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    selectLocationSuggestion: function (suggestion) {
      this.$store.state.search.text = suggestion;
      this.selectedLocations.push(suggestion);
    },
    fetchListings: async function () {
      this.loading = true
      try {
        const response = await axios.get('https://luxurywinelandsestates.co.za/api/fusion-listings.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.fusionListings = response.data
      } catch (error) {
        console.log(error);
      }
      this.loading = false
    }, 
    fetchListingsCommercial: async function () {
      this.loading = true
      try {
        const response = await axios.get('https://luxurywinelandsestates.co.za/api/fusion-listings-commercial.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.fusionListingsCommercial = response.data
      } catch (error) {
        console.log(error);
      }
      this.loading = false
    },
  }
}
</script>

<style scoped>
.force-height {
  height: 300px;
  object-fit: cover;
}
.social-links{
  position: unset;
  display: inline;
  float: right;
  margin-right: 20px;
}
.filterTwo {
  font-size: 9px;
  padding: 10px;
}
.tag {
  background-color: #255699;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
} 
</style>

